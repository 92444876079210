import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {ButtonPriority} from 'wix-ui-tpa';
import {classes} from './Footer.st.css';
import {CheckoutButton} from '../../../../../common/components/CheckoutButton/CheckoutButton';
import {CartButton} from '../CartButton/CartButton';
import {SecureCheckout} from '../../../../../common/components/SecureCheckout/SecureCheckout';
import {Violations} from '../../../../../common/components/Violations/Violations';
import settingsParams from '../../../settingsParams';
import stylesParams from '../../../stylesParams';
import {useSettings} from '@wix/tpa-settings/react';
import {Divider, Text} from 'wix-ui-tpa/cssVars';
import {LazyExpressCheckoutButtons} from '../../../../../common/components/LazyExpressCheckoutButtons';
import classNames from 'classnames';
import {useBooleanStyleForAnyBreakpoint} from '../../../../../common/useBooleanStyleForAnyBreakpoint';

export enum FooterDataHooks {
  root = 'Footer.root',
  subtotalContainer = 'Footer.subtotalContainer',
  subtotalLabel = 'Footer.subtotalLabel',
  subtotalValue = 'Footer.subtotalValue',
  subtotalDisclaimer = 'Footer.subtotalDisclaimer',
  currencyConverterDisclaimer = 'Footer.currencyConverterDisclaimer',
  expressCheckoutButtons = 'Footer.expressCheckoutButtons',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Footer = () => {
  const {t} = useTranslation();
  const {
    cart,
    shouldRenderEmptyState,
    shouldShowExpressCheckout,
    isCardTokenizationCart,
    checkoutButtonVisibilityInCss,
    goToCartButtonVisibilityInCss,
    SideCartFooterTextVisibilityInCss,
  } = useControllerProps().cartStore;
  const {
    shouldShowDynamicPaymentOptions,
    dynamicPaymentOptionsProps: {shouldRequestShipping, canCheckout},
  } = useControllerProps().cashierExpressStore;
  const {orderStore} = useControllerProps();
  const {isCheckoutButtonDisabled, isNavigationToCheckoutInProcess} = useControllerProps().navigationStore;
  const {get: getSettings} = useSettings();
  const {getBooleanStyle} = useBooleanStyleForAnyBreakpoint();
  if (shouldRenderEmptyState) {
    return false;
  }
  const {isMainCurrency} = orderStore;
  const shouldShowTotalsDisclaimer = getBooleanStyle(
    stylesParams.CART_SUMMARY_DISCLAIMER_VISIBILITY,
    SideCartFooterTextVisibilityInCss
  );

  const shouldShowSecureCheckout = getBooleanStyle(
    stylesParams.CART_SUMMARY_SECURE_BADGE_VISIBILITY,
    SideCartFooterTextVisibilityInCss
  );
  const shouldGoToCartButton = getBooleanStyle(
    stylesParams.CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY,
    goToCartButtonVisibilityInCss
  );
  const shouldShowCheckoutButton = getBooleanStyle(
    stylesParams.CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY,
    checkoutButtonVisibilityInCss
  );

  return (
    <section className={classes.root} data-hook={FooterDataHooks.root}>
      <Divider className={classes.divider} />
      <div className={classes.container}>
        <div
          className={classes.subtotalRow}
          aria-live="polite"
          role="status"
          data-hook={FooterDataHooks.subtotalContainer}>
          <Text tagName={'h3'} data-hook={FooterDataHooks.subtotalLabel} className={classes.subtotalLabel}>
            {t('sideCart.subtotal.label')}
          </Text>

          <Text data-hook={FooterDataHooks.subtotalValue} className={classes.subtotalValue} data-wix-sub-totals-value>
            {cart.convertedTotals.formattedItemsTotal}
          </Text>
        </div>
        <div className={classes.disclaimers}>
          {!isMainCurrency && (
            <Text data-hook={FooterDataHooks.currencyConverterDisclaimer} className={classes.disclaimerText}>
              {t('cart.currency_converter_disclaimer', {mainCurrency: cart.currencyFormat.code})}
            </Text>
          )}
          {shouldShowTotalsDisclaimer ? (
            <Text
              data-hook={FooterDataHooks.subtotalDisclaimer}
              className={classNames(
                classes.disclaimerText,
                SideCartFooterTextVisibilityInCss ? classes.disclaimerTextVisibility : undefined
              )}>
              {getSettings(settingsParams.CART_DISCLAIMER_TEXT) as string}
            </Text>
          ) : null}
        </div>
        <Violations />
        <div className={classes.buttons}>
          {shouldShowCheckoutButton ? (
            <CheckoutButton
              className={checkoutButtonVisibilityInCss ? classes.checkoutButtonVisibility : undefined}
              priority={ButtonPriority.primary}
              disabled={isCheckoutButtonDisabled}
              text={getSettings(settingsParams.SIDE_CART_CHECKOUT_BUTTON) as string}
              isNavigationToCheckoutInProcess={isNavigationToCheckoutInProcess}
            />
          ) : null}
          {shouldGoToCartButton ? (
            <CartButton className={goToCartButtonVisibilityInCss ? classes.cartButtonVisibility : null} />
          ) : null}
          {shouldShowExpressCheckout && shouldShowDynamicPaymentOptions && !isCardTokenizationCart ? (
            <div className={classes.expressCheckoutButtonsVisibility}>
              <LazyExpressCheckoutButtons
                layout={'vertical'}
                isShippable={shouldRequestShipping}
                estimatedTotal={cart.convertedTotals.itemsTotal.toString()}
                canStartPayment={canCheckout}
                dataHook={FooterDataHooks.expressCheckoutButtons}
              />
            </div>
          ) : null}
        </div>
        {shouldShowSecureCheckout ? (
          <SecureCheckout
            text={t('sideCart.secureCheckout.badge')}
            className={SideCartFooterTextVisibilityInCss ? classes.secureCheckoutVisibility : undefined}
          />
        ) : null}
      </div>
    </section>
  );
};
